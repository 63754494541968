@charset "UTF-8";
/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Font Icons
----------------------------------------------------------------------------- */
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot?21099076");
  src: url("../fonts/icons.eot?21099076#iefix") format("embedded-opentype"), url("../fonts/icons.woff2?21099076") format("woff2"), url("../fonts/icons.woff?21099076") format("woff"), url("../fonts/icons.ttf?21099076") format("truetype"), url("../fonts/icons.svg?21099076#icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-dribbble:before {
  content: "\e801";
}

.icon-instagram:before {
  content: "\e802";
}

.icon-behance:before {
  content: "\e803";
}

.icon-linkedin:before {
  content: "\e804";
}

.icon-facebook:before {
  content: "\e805";
}

.icon-twitter:before {
  content: "\e806";
}

.icon-gplus:before {
  content: "\e807";
}

.icon-pinterest:before {
  content: "\e808";
}

.icon-tumblr:before {
  content: "\e809";
}

.icon-down:before {
  content: "\e810";
}

.icon-left:before {
  content: "\e811";
}

.icon-right:before {
  content: "\e812";
}

.icon-up:before {
  content: "\e813";
}

.icon-left-small:before {
  content: "\e814";
}

.icon-right-small:before {
  content: "\e815";
}

.icon-up-small:before {
  content: "\e816";
}

.icon-down-small:before {
  content: "\e817";
}

.icon-down-thin:before {
  content: "\e818";
}

.icon-left-thin:before {
  content: "\e819";
}

.icon-right-thin:before {
  content: "\e820";
}

.icon-up-thin:before {
  content: "\e821";
}

.icon-down-arrow:before {
  content: "\e822";
}

.icon-left-arrow:before {
  content: "\e823";
}

.icon-right-arrow:before {
  content: "\e824";
}

.icon-up-arrow:before {
  content: "\e825";
}

.icon-menu:before {
  content: "\e826";
}

.icon-home:before {
  content: "\e827";
}

.icon-lock:before {
  content: "\e828";
}

.icon-phone:before {
  content: "\e829";
}

.icon-chat:before {
  content: "\e830";
}

.icon-mail:before {
  content: "\e831";
}

.icon-comment:before {
  content: "\e832";
}

.icon-at:before {
  content: "\e833";
}

.icon-star:before {
  content: "\e834";
}

.icon-search:before {
  content: "\e835";
}

.icon-zoom:before {
  content: "\e836";
}

.icon-cog:before {
  content: "\e837";
}

.icon-link:before {
  content: "\e838";
}

.icon-share:before {
  content: "\e839";
}

.icon-check:before {
  content: "\e840";
}

.icon-cancel:before {
  content: "\e841";
}

.icon-plus:before {
  content: "\e842";
}

.icon-minus:before {
  content: "\e843";
}

.icon-close:before {
  content: "\e844";
}

.icon-user:before {
  content: "\e845";
}

.icon-spinner:before {
  content: "\e846";
}

.icon-youtube:before {
  content: "\e847";
}

a.social {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 5px;
  color: #fff !important;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 22px;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
  border: 0 !important;
  border-radius: 30px;
  transition: all 0.15s linear 0s;
}

a.social [class^=icon-]:before {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px;
}

a.social:hover,
a.social:active {
  font-size: 24px;
  border-radius: 4px;
  /*transform: rotate(360deg);*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a.social.dribbble:hover,
a.social.dribbble:active {
  background-color: #ea4c89;
}

a.social.instagram:hover,
a.social.instagram:active {
  background-color: #49789d;
}

a.social.behance:hover,
a.social.behance:active {
  background-color: #1769ff;
}

a.social.facebook:hover,
a.social.facebook:active {
  background-color: #3f5495;
}

a.social.twitter:hover,
a.social.twitter:active {
  background-color: #5d87c4;
}

a.social.linkedin:hover,
a.social.linkedin:active {
  background-color: #2575b2;
}

a.social.gplus:hover,
a.social.gplus:active {
  background-color: #cc4436;
}

a.social.pinterest:hover,
a.social.pinterest:active {
  background-color: #c2142f;
}

a.social.tumblr:hover,
a.social.tumblr:active {
  background-color: #529ecc;
}

a.social.youtube:hover,
a.social.youtube:active {
  background-color: #ff0000;
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-300.woff") format("woff"), url("../fonts/open-sans-v18-latin-300.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-300.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-regular.woff") format("woff"), url("../fonts/open-sans-v18-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600.woff") format("woff"), url("../fonts/open-sans-v18-latin-600.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-600italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700.woff") format("woff"), url("../fonts/open-sans-v18-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-700italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* fjalla-one-regular - latin */
@font-face {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/fjalla-one-v8-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/fjalla-one-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/fjalla-one-v8-latin-regular.woff2") format("woff2"), url("../fonts/fjalla-one-v8-latin-regular.woff") format("woff"), url("../fonts/fjalla-one-v8-latin-regular.ttf") format("truetype"), url("../fonts/fjalla-one-v8-latin-regular.svg#FjallaOne") format("svg"); /* Legacy iOS */
}
/* roboto-slab-regular - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-regular.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-regular.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-regular.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-regular.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-600 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-600.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-600.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-600.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-600.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-700.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-700.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-700.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-regular.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/montserrat-v25-latin-800.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-800.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* material icons */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIcons-Regular.woff2") format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsOutlined-Regular.otf") format("woff2");
}
.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsRound-Regular.otf") format("woff2");
}
.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* ----------------------------------------------------------------------------
    CSS Variable, needs to be set in :root
----------------------------------------------------------------------------- */
:root {
  --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* ----------------------------------------------------------------------------
    Smooth Scrolling
----------------------------------------------------------------------------- */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
[id] {
  scroll-margin-top: 1rem;
}

/* ----------------------------------------------------------------------------
    Typography
----------------------------------------------------------------------------- */
body {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  font-family: var(--font-family);
  line-height: 1.4;
  color: #333;
}

h1,
.h1 {
  font-size: 1.602em;
}

h2,
.h2 {
  font-size: 1.424em;
}

h3,
.h3 {
  font-size: 1.266em;
}

h4,
.h4 {
  font-size: 1.125em;
}

h5,
.h5 {
  font-size: 1em;
}

@media (min-width: 48em) {
  /* Medium devices (tablets, 768px and up) */
  h1,
  .h1 {
    font-size: 1.8em;
  }
  h2,
  .h2 {
    font-size: 1.55em;
  }
  h3,
  .h3 {
    font-size: 1.32em;
  }
  h4,
  .h4 {
    font-size: 1.125em;
  }
  h5,
  .h5 {
    font-size: 1em;
  }
}
@media (min-width: 62em) {
  /* Large devices (desktops, 992px and up) */
  h1,
  .h1 {
    font-size: 2.074em;
  }
  h2,
  .h2 {
    font-size: 1.728em;
  }
  h3,
  .h3 {
    font-size: 1.44em;
  }
  h4,
  .h4 {
    font-size: 1.2em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
@media (min-width: 75em) {
  /* Extra large devices (large desktops, 1200px and up) */
  h1,
  .h1 {
    font-size: 2.441em;
  }
  h2,
  .h2 {
    font-size: 1.953em;
  }
  h3,
  .h3 {
    font-size: 1.563em;
  }
  h4,
  .h4 {
    font-size: 1.25em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

a {
  color: #c74b00;
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: inherit;
}

a:hover,
a:active {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #943800;
}

a.img:hover,
a.img:active {
  text-decoration: none;
  border: 0;
}

a[href^="tel:"] {
  color: inherit;
  border: 0;
}

ul.nav,
ol.nav {
  margin-left: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cfix:after,
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

#content,
#main_content,
.container,
.container-fluid,
.break_word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* ----------------------------------------------------------------------------
    Forms Elements
----------------------------------------------------------------------------- */
.opacity-1 {
  opacity: 1 !important;
}

.display-none {
  display: none !important;
}

/* ----------------------------------------------------------------------------
    Buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Tables
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Modals
----------------------------------------------------------------------------- */
body.modal-open {
  overflow: auto !important;
  padding: 0 !important;
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header .close {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  margin-top: -0.7rem;
}

.modal-body .btn-close {
  opacity: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  z-index: 100;
  background-color: #ffffff;
  border-bottom-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

/* ----------------------------------------------------------------------------
  Dropdown List
----------------------------------------------------------------------------- */
.dropdown-list {
  margin: auto;
  width: auto;
  position: relative;
  box-sizing: border-box;
}

.dropdown-list ul.drop {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-list span.dropdown {
  display: block;
  margin: 0;
  padding: 0 10px;
  width: auto;
  height: 40px;
  line-height: 38px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #a1c4e5;
  cursor: pointer;
  overflow: hidden;
}

.dropdown-list span.dropdown.is-active {
  border-radius: 3px 3px 0 0;
}

.dropdown-list span.dropdown:hover,
.dropdown-list span.dropdown:active {
  background: #f1f1f1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.dropdown-list span.dropdown:after {
  float: right;
  margin-top: 0;
  margin-left: 10px;
  width: 15px;
  height: 100%;
  content: "\e817";
  color: ineherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}

.dropdown-list span.dropdown.is-active:after {
  content: "\e816";
}

.dropdown-list a:hover {
  text-decoration: none;
  border-bottom: 0;
}

.dropdown-list .drop {
  display: block;
  float: left;
  margin: 0;
  width: 100%;
  max-height: 0;
  position: absolute;
  background: #fff;
  top: 40px;
  border-radius: 0 0 3px 3px;
  z-index: 10;
  overflow-y: auto;
  opacity: 0;
  transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
}

.dropdown-list span.is-active + .drop {
  max-height: 350px;
  border: 1px solid #a1c4e5;
  border-top: 0;
  opacity: 1;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.dropdown-list .drop li {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  height: auto;
  border-top: 1px dotted #ccc;
}

.dropdown-list .drop li:first-child {
  border-top: 0;
}

.dropdown-list .drop li a {
  float: none;
  display: block;
  margin: 0;
  padding: 6px 10px 6px 25px;
  height: auto;
  width: auto;
  text-transform: none;
  color: inherit;
  background: #fff;
  text-align: left;
  border-radius: 0;
  border: 0;
}

.dropdown-list .drop li a:hover,
.dropdown-list .drop li a:active {
  background: #f1f1f1;
}

.dropdown-list .drop li a:before {
  display: inline-block;
  margin-left: -15px;
  margin-right: 5px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}

ul,
ol {
  padding-left: 30px;
}

ul li,
ol li {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

ul.check,
ul.star,
ul.carrot {
  margin-left: 0;
  padding-left: 0;
  list-style: none outside;
  line-height: inherit;
}

ul.check li,
ul.star li,
ul.carrot li {
  padding-left: 1.3em;
  margin-left: 0;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  position: relative;
}

ul.check li li,
ul.star li li,
ul.carrot li li {
  padding-left: 0;
}

ul.carrot li {
  padding-left: 0.9em;
}

ul.check > li:before,
ul.star > li:before,
ul.carrot > li:before {
  display: block;
  margin-top: 0.1em;
  width: 1em;
  height: inherit;
  color: #c74b00;
  font-size: 0.9em;
  font-weight: normal !important;
  word-wrap: normal;
  position: absolute;
  left: 0;
}

ul.check > li:before {
  content: "\e840";
  font-family: "icons";
}

ul.star > li:before {
  content: "\e834";
  font-family: "icons";
}

ul.carrot > li:before {
  content: "»";
  font-family: "icons";
  margin-top: 0.2em;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 1em;
}

/* ----------------------------------------------------------------------------
  Accordion
----------------------------------------------------------------------------- */
.accordion-wrap {
  margin-bottom: 1rem;
}
.accordion-wrap .accordion {
  padding: 0;
  border-bottom: 1px dotted #ccc;
}
.accordion-wrap .accordion:first-child {
  border-top: 1px dotted #ccc;
}
.accordion-wrap .accordion .accordion-toggle {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: calc(1em + 10px);
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
}
.accordion-wrap .accordion .accordion-toggle:before {
  float: right;
  color: inherit;
  content: "\e817";
  font-family: "icons";
  font-size: 1em;
  font-weight: normal !important;
  height: inherit;
  width: 1em;
  margin-right: -1em;
  text-align: right;
}
.accordion-wrap .accordion .accordion-toggle:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.accordion-wrap .accordion.is-open > .accordion-content {
  padding-top: 5px;
  opacity: 1;
  transform: scaleY(1);
}
.accordion-wrap .accordion.is-open > .accordion-toggle:before {
  content: "\e816";
}
.accordion-wrap .accordion .accordion-content {
  padding: 0 10px;
  max-height: 0;
  opacity: 0;
  transform-origin: top;
  transition: max-height 0.35s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
}

/* ----------------------------------------------------------------------------
  Bootstrap Overrides
----------------------------------------------------------------------------- */
b,
strong {
  font-weight: 600;
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5 {
  margin-top: 0;
  font-weight: 400;
}

input[type=number] {
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: #e0e0e0 !important;
}

.custom-control.is-invalid .custom-control-input ~ .custom-control-label, .custom-control:invalid .custom-control-input ~ .custom-control-label {
  color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input ~ .custom-control-label:before, .custom-control:invalid .custom-control-input ~ .custom-control-label:before {
  border-color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input:focus ~ .custom-control-label:before, .custom-control:invalid .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control.custom-checkbox {
  padding-left: 1.75rem;
}
.custom-control.custom-checkbox .custom-control-label:before, .custom-control.custom-checkbox .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}
.custom-control.custom-radio {
  padding-left: 1.75rem;
}
.custom-control.custom-radio .custom-control-label:before, .custom-control.custom-radio .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}

.form-control.is-invalid {
  border-color: #fb4f16;
}
.form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-image: none;
}

.custom-select.is-invalid {
  border-color: #fb4f16;
}
.custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
}

.table {
  word-wrap: normal;
  word-break: normal;
}
.table.align-middle th,
.table.align-middle td {
  vertical-align: middle;
}
.table.table-sm td,
.table.table-sm th {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.list-group-item {
  margin-top: 0;
  margin-bottom: 0;
}

/* ----------------------------------------------------------------------------
  ASC Default stuff
----------------------------------------------------------------------------- */
.price {
  display: inline-block;
  padding: 0;
  width: auto;
  height: auto;
  color: #555;
  text-align: center;
  font: bold 0 "Trebuchet MS", "Droid Serif", Arial, sans-serif;
  overflow: visible;
  text-shadow: 0 0 40px #333;
  vertical-align: middle;
  color: transparent;
}

.price.static {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
}

.price.shown {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price.ribbon {
  margin-top: -6px;
  margin-bottom: 0;
  padding: 28px 0 0 0;
  width: 140px;
  height: 93px;
  color: #fff;
  background: url(../images/price_bg.png) no-repeat 0 0;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.burst {
  padding: 60px 0 0 0;
  width: 155px;
  height: 155px;
  color: #fff;
  background: url(../images/price_bg_burst.png) no-repeat 50% 50%;
  text-shadow: 0 0 25px #fff;
  color: transparent;
}

.price.ribbon.static,
.price.burst.static {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price.ribbon.shown,
.price.burst.shown {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price span.dollar-sign,
.price span.cents,
.price span.cents-fees,
.price span.only {
  display: inline-block;
  margin: 5px 0 0 0;
  font-size: 30px;
  line-height: 30px;
  vertical-align: top;
}

.price span.only {
  margin-bottom: 5px;
  font-size: 26px;
  display: block;
}

.price span.dollars,
.price span.dollars-fees {
  display: inline-block;
  margin-left: -1px;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -1px;
  vertical-align: middle;
}

.price span.cents,
.price span.cents-fees {
  margin: 4px 0 0 3px;
  letter-spacing: -1px;
}

.price.shown span.reg-price {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  text-decoration: line-through;
  padding-left: 3px;
  vertical-align: middle;
  text-shadow: none;
}

.price.ribbon span.reg-price {
  color: #eee;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price span.dollar-sign {
  filter: blur(10px);
}

.price.shown span.dollar-sign {
  filter: blur(0);
}

.price span.dollars:after,
.price span.dollars-fees:after,
.price span.cents:after,
.price span.cents-fees:after,
.price span.dollars:after,
.price span.cents:after {
  display: inline-block;
  content: "##";
  text-shadow: 0 0 20px currentcolor;
  filter: blur(10px);
}

.price.shown span.dollars:after,
.price.shown span.dollars-fees:after,
.price.shown span.cents:after,
.price.shown span.cents-fees:after,
.price.shown span.dollars:after,
.price.shown span.cents:after {
  display: none;
  filter: blur(0);
}

/* -- for dynamic LGAs -- */
.lga-item {
  display: none;
  opacity: 0;
}
.lga-item.lga-replaced {
  display: inline;
  opacity: 1;
}

/* ----------------------------------------------------------------------------
  Accessibility styles
----------------------------------------------------------------------------- */
:is(nav) :is(ul) :focus-visible,
:is(.dropdown-list) :is(ul) :focus-visible {
  outline: 2px solid #007aff !important;
  outline-offset: -2px;
}

/* -- Customize Bootstrap Utilities-- */
.w-auto {
  max-width: 100% !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: 100% !important;
  }
  .w-sm-5 {
    width: 5% !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-md-auto {
    width: 100% !important;
  }
  .w-md-5 {
    width: 5% !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-auto {
    width: 100% !important;
  }
  .w-lg-5 {
    width: 5% !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-auto {
    width: 100% !important;
  }
  .w-xl-5 {
    width: 5% !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-auto {
    width: 100% !important;
  }
  .w-xxl-5 {
    width: 5% !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.ls-10 {
  letter-spacing: 0.1rem;
}

.ls-15 {
  letter-spacing: 0.15rem;
}

.ls-20 {
  letter-spacing: 0.2rem;
}

.ls-25 {
  letter-spacing: 0.25rem;
}

.lh-1 {
  line-height: 1;
}

.lh-12 {
  line-height: 1.2;
}

.lh-14 {
  line-height: 1.4;
}

.lh-16 {
  line-height: 1.6;
}

.lh-18 {
  line-height: 1.8;
}

.lh-20 {
  line-height: 2;
}

.lh-25 {
  line-height: 2.5;
}

/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#nav-wrap {
  background: #eae8e6;
}

#menutoggle {
  display: block;
  margin: 0;
  padding: 0 18px;
  width: auto;
  color: #555;
  background: #eae8e6;
  height: 50px;
  font-size: 18px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0;
  border-radius: 4px 4px 0 0;
  transition: background 0.2s linear 0s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#menutoggle span {
  display: none;
}
#menutoggle .hamburger-box {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 24px;
  vertical-align: middle;
}
#menutoggle .hamburger-box .hamburger-inner {
  display: block;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
}
#menutoggle .hamburger-box .hamburger-inner,
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  position: absolute;
  width: 30px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, width;
  border-radius: 4px;
  background-color: #555;
  right: 0;
}
#menutoggle .hamburger-box .hamburger-inner {
  top: 0;
  width: 25px;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  width: 22px;
}
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  display: block;
  content: "";
}
#menutoggle .hamburger-box .hamburger-inner::before {
  top: 10px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, opacity;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  top: 20px;
}
#menutoggle.is-active .hamburger-inner,
#menutoggle.is-active .hamburger-inner::after,
#menutoggle.is-active .hamburger-inner::before {
  width: 30px;
}
#menutoggle.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
#menutoggle.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
#menutoggle.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}
#menutoggle:hover {
  background-color: black;
}
#menutoggle:hover .hamburger-box .hamburger-inner,
#menutoggle:hover .hamburger-box .hamburger-inner::after,
#menutoggle:hover .hamburger-box .hamburger-inner::before {
  width: 30px;
}
#menutoggle:hover, #menutoggle:active, #menutoggle.is-active {
  background-color: #d9d5d1;
  border-radius: 4px 4px 0 0;
}
#menutoggle.is-active + ul {
  max-height: 1000px;
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out;
}

#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  position: relative;
  z-index: 1000;
  border-top: 1px solid #eae8e6;
}
#nav > ul {
  visibility: hidden;
}
#nav ul {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  width: auto;
  opacity: 0;
  max-height: 0;
  background: #eae8e6;
  overflow: hidden;
  transition: max-height 0.25s cubic-bezier(0, 0.7, 0, 1), opacity 0.2s ease-in-out;
  border-radius: 0 0 4px 4px;
  top: 1px;
  border: 0;
  position: relative;
}
#nav ul.submenu {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 0;
  background: none;
  opacity: 1;
  position: relative;
  top: 0;
  transition: max-height 0.15s linear 0s;
}
#nav ul.submenu:hover {
  display: block !important;
}
#nav ul.submenu a {
  padding-left: 40px;
  background: none;
}
#nav ul.submenu a:before {
  display: inline-block;
  margin-right: 5px;
  margin-left: -15px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
#nav li {
  display: block;
  margin: 0;
  padding: 0;
  height: auto;
  border: 0;
  border-top: 1px dotted #fbfbfb;
}
#nav li:first-child {
  border-top: 0;
}
#nav li.onmobile {
  display: block;
}
#nav a,
#nav span.submenu {
  float: none;
  display: block;
  margin: 0;
  padding: 9px 20px;
  height: auto;
  color: #555;
  line-height: 1.6em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  text-align: left;
  text-decoration: none;
  font-size: 16px;
  font-weight: normal;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background 0.17s linear 0s;
}
#nav span.submenu:after,
#nav a.submenu:after {
  float: right;
  margin-left: 10px;
  width: 15px;
  height: inherit;
  content: "\e817";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#nav span.submenu.is-active,
#nav a.submenu.is-active {
  border-bottom: 1px dotted #fbfbfb;
}
#nav span.submenu.is-active + ul.submenu,
#nav a.submenu.is-active + ul.submenu {
  max-height: 360px;
  overflow-y: auto;
}
#nav span.submenu.is-active:after,
#nav a.submenu.is-active:after {
  content: "\e816";
}
#nav a:hover,
#nav a.is-active,
#nav li:hover span.submenu,
#nav li:hover a.submenu,
#nav ul.submenu a:hover,
#nav ul.submenu a.is-active {
  background: #fbfbfb;
}

@media (min-width: 36em) {
  /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px;
  }
  #menutoggle span {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
  #menutoggle:before {
    margin-right: 10px;
  }
}
@media (min-width: 62em) {
  /* Large devices (desktops, 992px and up) */
  #nav-wrap {
    background: #eae8e6;
  }
  #menutoggle {
    display: none;
  }
  #nav {
    height: 45px;
    background: transparent;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
  }
  #nav > ul {
    visibility: visible;
  }
  #nav ul {
    float: left;
    width: 100%;
    height: auto;
    max-height: 45px;
    opacity: 1;
    margin: 4px 0;
    position: relative;
    z-index: 10;
    top: 0;
    overflow: visible;
  }
  #nav ul.submenu {
    float: left;
    margin: 0;
    padding: 0;
    width: 250px;
    max-height: 0;
    position: absolute;
    opacity: 0;
    background: #fbfbfb;
    top: 95%;
    z-index: 10;
    border-radius: 0 3px 3px 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
    overflow: hidden;
    overflow-y: auto;
  }
  #nav ul.submenu li {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    height: auto;
  }
  #nav ul.submenu li:first-child a {
    border: 0;
  }
  #nav ul.submenu a {
    float: none;
    display: block;
    margin: 0;
    padding: 7px 15px 7px 28px;
    height: auto;
    text-transform: none;
    text-align: left;
    line-height: 1.5em;
    border-radius: 0;
    border: 0;
    border-top: 1px dotted #eae8e6;
  }
  #nav li {
    float: left;
    margin: 0 5px;
    padding: 0;
    height: 37px;
    width: auto;
    min-width: auto;
    position: relative;
    border: 0;
    overflow: visible;
  }
  #nav li:first-child {
    margin-left: 0;
    border-left: 0;
  }
  #nav li.last {
    border-right: 0;
  }
  #nav li.right {
    float: right;
    margin-right: 0;
    border-right: 0;
  }
  #nav li.onmobile {
    display: none;
  }
  #nav li:hover {
    z-index: 11;
  }
  #nav a,
  #nav span.submenu {
    float: left;
    margin: 0;
    padding: 0 14px;
    height: 37px;
    border: 0;
    color: #555;
    font-weight: normal;
    font-size: 14px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    transition: background 0.2s linear 0s;
    cursor: pointer;
  }
  #nav span.submenu:after,
  #nav a.submenu:after {
    display: none;
  }
  #nav a:hover,
  #nav a.is-active,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav li:hover a.submenu,
  #nav ul.submenu a:hover,
  #nav span.submenu.is-active,
  #nav a.submenu.is-active {
    background: #fbfbfb;
  }
  #nav li:hover a.submenu,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav span.submenu.is-active.hover,
  #nav a.submenu.is-active.hover {
    border-radius: 3px 3px 0 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  #nav li:hover ul.submenu,
  #nav ul.submenu.is-active {
    padding: 3px 0;
    left: 0;
    max-height: 360px;
    opacity: 1;
    display: block !important;
    visibility: visible !important;
  }
  #nav ul.submenu li a:hover,
  #nav ul.submenu li a.is-active {
    background: #eae8e6;
  }
}
/* ----------------------------------------------------------------------------
	Buttons
----------------------------------------------------------------------------- */
@keyframes btnProcessing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cta-btn {
  display: inline-block;
  padding: 10px 0 8px 0;
  color: #fff;
  background-color: #555555;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 220px;
  line-height: 1.35;
  font-size: 21px;
  font-weight: normal;
  font-family: "Fjalla One", Impact, Verdana;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  overflow: hidden;
  transition: background-color 0.1s ease-in-out 0.05s;
  border-bottom: 0;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.cta-btn:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #3c3c3c 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}
.cta-btn:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}
.cta-btn[data-pid] {
  position: relative;
  transition: padding-left 0.3s, opacity 0.5s;
}
.cta-btn[data-pid].btn-processing:before {
  display: inline-block;
  margin-right: 7px;
  content: "";
  top: 50%;
  left: 0.7rem;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  border-radius: 20px;
  border: 3px solid #fff;
  border-top-color: black;
  border-left-color: black;
  opacity: 0.5;
  animation: btnProcessing 1s linear infinite;
}
.cta-btn.disabled {
  background: #ddd !important;
  pointer-events: none;
  color: #616161 !important;
}

.cta-btn.fancy {
  background: #555555;
  background-image: linear-gradient(#555555, #434343);
}

.cta-btn:hover,
.cta-btn:active {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #3f3f3f;
  border: 0;
}

.cta-btn.big {
  padding: 13px 0 10px 0;
}

.cta-btn.big.outline {
  padding: 12px 0 9px 0;
}

.cta-btn.small {
  padding: 9px 0 7px 0;
  font-size: 18px;
}

.cta-btn.small.outline {
  padding: 8px 0 6px 0;
}

.cta-btn.dark {
  color: #fff;
  background: #555555;
}

.cta-btn.orange {
  color: #fff;
  background: #ff571f;
}

.cta-btn.yellow {
  color: #333;
  background: #ffc107;
}

.cta-btn.green {
  color: #fff;
  background: #49a752;
}

.cta-btn.red {
  color: #fff;
  background: #f43325;
}

.cta-btn.blue {
  color: #fff;
  background: #0087e0;
}

.cta-btn[class~=outline] {
  border: 1px solid #555555;
}

.cta-btn.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.dark.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.orange.outline {
  color: #ff571f;
  background: transparent;
  border-color: #ff571f;
}

.cta-btn.green.outline {
  color: #49a752;
  background: transparent;
  border-color: #49a752;
}

.cta-btn.red.outline {
  color: #f43325;
  background: transparent;
  border-color: #f43325;
}

.cta-btn.yellow.outline {
  color: #ffc107;
  background: transparent;
  border-color: #ffc107;
}

.cta-btn.blue.outline {
  color: #0087e0;
  background: transparent;
  border-color: #0087e0;
}

.cta-btn.pill {
  border-radius: 100px;
}

.cta-btn.dark.fancy {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  background: #555555;
  background-image: linear-gradient(#555555, #434343);
}

.cta-btn.dark:hover,
.cta-btn.dark:active {
  background: #3f3f3f;
}

.cta-btn.outline:hover,
.cta-btn.outline:active,
.cta-btn.dark.outline:hover,
.cta-btn.dark.outline:active {
  background: rgba(85, 85, 85, 0.1);
}

.cta-btn.orange.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background: #ff571f;
  background-image: linear-gradient(#ff571f, #fa3f00);
}

.cta-btn.orange:hover,
.cta-btn.orange:active {
  background: #f33d00;
}

.cta-btn.orange.outline:hover,
.cta-btn.orange.outline:active {
  background: rgba(255, 87, 31, 0.1);
}

.cta-btn.green.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #49a752;
  background-image: linear-gradient(#49a752, #3e8e46);
  color: #fff;
}

.cta-btn.green:hover,
.cta-btn.green:active {
  background: #3c8943;
}

.cta-btn.green.outline:hover,
.cta-btn.green.outline:active {
  background: rgba(73, 167, 82, 0.1);
}

.cta-btn.red.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #f43325;
  background-image: linear-gradient(#f43325, #ea1b0c);
}

.cta-btn.red:hover,
.cta-btn.red:active {
  background: #e21a0b;
}

.cta-btn.red.outline:hover,
.cta-btn.red.outline:active {
  background: rgba(244, 51, 37, 0.1);
}

.cta-btn.blue.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #0087e0;
  background-image: linear-gradient(#0087e0, #0071bc);
}

.cta-btn.blue:hover,
.cta-btn.blue:active {
  background: #006db5;
}

.cta-btn.blue.outline:hover,
.cta-btn.blue.outline:active {
  background: rgba(0, 135, 224, 0.1);
}

.cta-btn.yellow.fancy {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background: #ffc107;
  background-image: linear-gradient(#ffce3a, #edb100);
}

.cta-btn.yellow:hover,
.cta-btn.yellow:active {
  background: #ffca2b;
}

.cta-btn.yellow.outline:hover,
.cta-btn.yellow.outline:active {
  background: rgba(255, 193, 7, 0.1);
}

/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
}

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}

.font-weight-strong {
  font-weight: 600 !important;
}

.wrap {
  padding: 30px 0;
}

.container-fluid.but-fixed {
  max-width: 1356px;
}

@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #eae9e2;
  color: #696969;
  border-top: 1px solid #999;
  font-size: 0.9rem;
}
.footer p {
  margin: 0 0 5px 0;
}
.footer p.trustmark {
  display: none;
  margin: 0;
}
.footer a {
  color: #696969;
}
.footer .social a {
  border: 0;
}
.footer img {
  display: inline-block;
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: #243f86 !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}

body {
  background: #faf9f5;
}

#header-wrap {
  background: #fff url(../images/header_wrap_bg.jpg) repeat-x top center;
  border-bottom: 1px solid #fff;
}

#header {
  float: none;
}
#header a.logo {
  float: left;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 0;
  width: 75%;
  max-width: 650px;
}
#header a.logo img {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  width: auto;
  height: auto;
  max-width: 100%;
}
#header a.logo span.big,
#header a.logo span.small {
  display: inline;
  font-size: 1.5em;
  font-weight: 500;
  color: #555;
  line-height: 1em;
  vertical-align: middle;
}
#header a.logo span.big {
  font-weight: 600;
  font-family: var(--font-family);
}
#header a.logo span.small {
  display: none;
  margin-top: 0;
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: normal;
}
#header #support {
  display: none;
  float: right;
  margin: 0 0 0 0;
  width: auto;
}
#header #support a.help-center {
  float: right;
  margin: 0 0 0 0;
  padding: 0 12px 2px 12px;
  font-weight: normal;
  color: #555;
  background: #eae8e6;
  text-decoration: none;
  border-radius: 0 0 4px 4px;
  border: 0;
}
#header #support a.help-center:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e830";
  color: inherit;
  font-family: "icons";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header #support a.help-center:hover {
  background: #d9d5d1;
}

#banner-wrap {
  background: #ede8d4 url(../images/banner_wrap_bg.jpg) repeat-x top center;
  border-bottom: 1px solid #999;
}

#banner {
  padding-bottom: 85px;
}
#banner ul li:before {
  color: #dc5548;
}

.orange {
  color: #c74b00;
}

#records {
  margin-top: -92px;
  border: 1px solid #999;
  border-top: 5px solid #999;
}

#record-table .record,
#record-table .pricing {
  font-size: 24px;
  font-weight: bold;
}
#record-table .record,
#record-table .pricing,
#record-table .order,
#record-table .link {
  width: 100%;
}
#record-table .line {
  border-bottom: 1px dotted #66ccff;
  font-size: 0;
}
#record-table .line:first-child {
  border-top: 1px dotted #66ccff;
}
#record-table .line:hover {
  background: #e5f6ff;
}
#record-table .link,
#record-table .rates {
  font-size: 14px;
}
#record-table .link {
  cursor: pointer;
}
#record-table a {
  color: #333;
}

.inner #record-table .record,
.inner #record-table .pricing {
  font-size: 18px;
}

#content-wrap {
  background: #faf9f7;
}

.cta-btn.brown {
  max-width: 100%;
  color: #333;
  background: #e3e2d9;
  background-image: linear-gradient(#eae9e2, #d7d5c9);
}
.cta-btn.brown:hover {
  background: #e3e2d9;
  color: #333;
}
.cta-btn.commercial {
  max-width: 350px;
}

#aside .section {
  background: #fff url(../images/section_bg.jpg) no-repeat top right;
  background-size: cover;
  border-top: 4px solid #e6e4df;
}
#aside #asc p {
  font-size: 0.9em;
}

.vehiclereport {
  border: 1px solid #a1c4e5;
}
.vehiclereport h2 {
  color: #06f;
}

.underline {
  text-decoration: underline;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media (min-width: 576px) {
  #record-table .pricing,
  #record-table .link,
  #record-table .rates,
  #record-table .order {
    width: 33.3333333%;
  }
}
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  #header a.logo img {
    max-width: 100%;
  }
  #record-table .record {
    width: 40%;
  }
  #record-table .pricing {
    width: 20%;
  }
  #record-table .order {
    margin-left: 20% !important;
    width: 20%;
  }
  #record-table .link {
    margin-left: -40% !important;
    width: 20%;
  }
  #record-table .rates {
    width: 20%;
  }
}
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
}
@media (min-width: 992px) {
  #header-wrap {
    border-top: 3px solid #eae8e6;
  }
  #header-wrap #header #support {
    display: block;
  }
  #header a.logo {
    width: 50%;
    max-width: 260px;
  }
  #records #record-table {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
@media (min-width: 1200px) {
  #banner {
    background: transparent url(../images/banner_bg.png) no-repeat 20% bottom;
  }
  #banner #details {
    display: inline-block;
  }
  #banner #details ul {
    font-size: 18px;
  }
}