/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.font-weight-strong {
  font-weight: 600 !important;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #eae9e2;
  color: #696969;
  border-top: 1px solid #999;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #696969;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-secondary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
body {
  background: #faf9f5;
}
#header-wrap {
  background: #fff url(../images/header_wrap_bg.jpg) repeat-x top center;
  border-bottom: 1px solid #fff;
}
#header {
  float: none;
  a.logo {
    float: left;
    margin-top: 12px;
    margin-bottom: 12px;
    border: 0;
    width: 75%;
    max-width: 650px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 100%;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.help-center {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #555;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: darken($theme-primary, 7.5%);
      }
    }
  }
}
#banner-wrap {
  background: #ede8d4 url(../images/banner_wrap_bg.jpg) repeat-x top center;
  border-bottom: 1px solid #999;
}
#banner {
  padding-bottom: 85px;
  ul {
    li {
      &:before {
        color: #dc5548;
      }
    }
  }
}
.orange {
  color: #c74b00;
}
#records {
  margin-top: -92px;
  border: 1px solid #999;
  border-top: 5px solid #999;
}

#record-table {
  .record,
  .pricing {
    font-size: 24px;
    font-weight: bold;
  }
  .record,
  .pricing,
  .order,
  .link {
    width: 100%;
  }
  .line {
    border-bottom: 1px dotted #66ccff;
    font-size: 0;
    &:first-child {
      border-top: 1px dotted #66ccff;
    }
    &:hover {
      background: #e5f6ff;
    }
  }
  .link,
  .rates {
    font-size: 14px;
  }
  .link {
    cursor: pointer;
  }
  a {
    color: #333;
  }
}
.inner #record-table {
  .record,
  .pricing {
    font-size: 18px;
  }
}

#content-wrap {
  background: #faf9f7;
}

.cta-btn {
  &.brown {
    max-width: 100%;
    color: #333;
    background: #e3e2d9;
    background-image: linear-gradient(#eae9e2, #d7d5c9);
    &:hover {
      background: #e3e2d9;
      color: #333;
    }
  }
  &.commercial {
    max-width: 350px;
  }
}

#aside {
  .section {
    background: #fff url(../images/section_bg.jpg) no-repeat top right;
    background-size: cover;
    border-top: 4px solid #e6e4df;
  }
  #asc p {
    font-size: 0.9em;
  }
}

.vehiclereport {
  border: 1px solid #a1c4e5;
  h2 {
    color: #06f;
  }
}

.underline {
  text-decoration: underline;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #record-table {
    .pricing,
    .link,
    .rates,
    .order {
      width: 33.3333333%;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    margin-top: 12px;
    margin-bottom: 12px;
    img {
      max-width: 100%;
    }
  }
  #record-table {
    .record {
      width: 40%;
    }
    .pricing {
      width: 20%;
    }
    .order {
      margin-left: 20% !important;
      width: 20%;
    }
    .link {
      margin-left: -40% !important;
      width: 20%;
    }
    .rates {
      width: 20%;
    }
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
    }
  }
  #header a.logo {
    width: 50%;
    max-width: 260px;
  }
  #records {
    #record-table {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner {
    background: transparent url(../images/banner_bg.png) no-repeat 20% bottom;
    #details {
      display: inline-block;
      ul {
        font-size: 18px;
      }
    }
  }
}
